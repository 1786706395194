import React, { useState, useEffect } from "react";
import "../scss/FaqPage.scss";
import Seo from "../components/seo/seo";
import BubblesBgHeader from "../shared/bubbles-bg-header/BubblesBgHeader";
import AccordionsList from "../shared/accordions-list/AccordionsList";
import axios from "../http/axios";

const FaqPage = () => {
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    axios.get(`/faqs`).then((res) => {
      setFaq(res.data);
    });
  }, []);
  return (
    <div className="faq-page">
      <Seo title="FAQ -" />
      <BubblesBgHeader text="FAQ" />
      <div className="questions-block container mt-5">
        <div className="row mb-5">
          <div className="col-12 ">
            <div className="introduction pb-3 mb-3">
              Below are some of the most commonly asked questions.
              <br />
              If you have any specific questions that I have not answered here,
              email me and I will be happy to answer them.
            </div>
          </div>
          <AccordionsList items={faq} />
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
